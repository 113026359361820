import liff from '@line/liff'
import { store as rstore } from '@/store/index'
import ApiService from './ApiService'

export default class LiffService {
  init (): void {
    liff.init({
      liffId: process.env.VUE_APP_LIFF_ID ?? '',
      withLoginOnExternalBrowser: false
    }).then(() => {
      rstore.state.liff.InClient = liff.isInClient()
      rstore.state.liff.LoggedIn = liff.isLoggedIn()
      rstore.state.liff.OS = liff.getOS()
      rstore.state.liff.LineVersion = liff.getLineVersion()
      if (liff.isLoggedIn()) {
        liff.getProfile().then((profile) => {
          rstore.state.liff.userId = profile.userId
          rstore.state.liff.idToken = liff.getIDToken() ?? ''
          const apiService = new ApiService()
          apiService.getVoteInfo(profile.userId, rstore.state.liff.idToken).then((ret) => {
            // ret.data.userAttribute = false
            console.log(ret.data.userAttribute)
            localStorage.setItem('userAttribute', ret.data.userAttribute)
            rstore.state.userAttribute = ret.data.userAttribute
            rstore.state.vote = { vote1: ret.data.vote1, vote2: ret.data.vote2, vote3: ret.data.vote3, vote4: ret.data.vote4 }
            rstore.state.voted = ret.data.vote1 !== '0'

            if (ret.data.userAttribute === true) {
              // window.location.href = ''

            }
          })
        })
      }
    })
  }

  login (): void {
    if (!liff.isLoggedIn()) {
      liff.login()
    }
  }

  logout (): void {
    if (liff.isLoggedIn()) {
      liff.logout()
    }
  }
}
