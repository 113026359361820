
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import ApiService from '@/module/ApiService'

@Options({
  data () {
    return {
      gender: '',
      age: '',
      address: ''
    }
  },
  mounted () {
    // this.$store.commit('setBackPage', '/')
    this.$store.commit('setHeaderShow', true)
  },
  computed: {
    entryBtnEnable: function () {
      return !(this.gender && this.age && this.address)
    },
    ...mapGetters(['getUserId', 'getIdToken'])
  },
  methods: {
    sendProfile: function () {
      const apiService = new ApiService()
      apiService.setUserAttribute(this.getUserId, this.gender, this.age, this.address, this.getIdToken).then((ret) => {
        console.log(ret)
        if (ret.status === 200) {
          this.$store.commit('setUserAttribute', ret.data.userAttribute)
          localStorage.setItem('userAttribute', ret.data.userAttribute)
          this.$router.push('/list')
        } else {
          alert('登録に失敗しました。しばらく立ってからアクセスしてください')
        }
      })
    }
  }
})
export default class UserProfile extends Vue {
}
