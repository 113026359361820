
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import LiffService from '@/module/LiffService'

@Options({
  methods: {
    setMeta (route: any) {
      // タイトルを設定
      if (route.meta.title) {
        const setTitle = route.meta.title
        document.title = setTitle
      }
      // ディスクリプションを設定
      if (route.meta.desc) {
        const setDesc = route.meta.desc
        const description = document.querySelector("meta[name='description']")
        if (description) description.setAttribute('content', setDesc)
      }
    }
  },
  mounted () {
    const liff = new LiffService()
    liff.init()

    const route = this.$route
    this.setMeta(route)
  },
  watch: {
    '$route' (route, from) {
      this.setMeta(route)
      if (this.getUserAttribute === false && ['Detail', 'List'].includes(this.$route.name)) {
        this.$router.push('/user_profile')
      }
    },
    getUserAttribute (val) {
      if (!val && ['Detail', 'List'].includes(this.$route.name)) {
        this.$router.push('/user_profile')
      }
    }
  },
  computed: {
    ...mapGetters({
      headerShow: 'headerShow',
      backPage: 'backPage',
      getUserAttribute: 'getUserAttribute'
    })
  }
})
export default class App extends Vue {
  msg!: string
}
