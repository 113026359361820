import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import UserProfile from '../views/UserProfile.vue'
import List from '../views/List.vue'
import Detail from '../views/Detail5.vue'
import NotFoundError from '../views/NotFoundError.vue'
// import Final from '@/views/Final.vue'

const actressList = {
  '001': '安藤帆花',
  '002': '岸本小雪',
  '003': '栗原一菜',
  '004': '坂本彩',
  '005': '樋口琴美',
  '006': '宮治果緒',
  '007': '間鍋春希',
  '008': '大和奈央',
  '009': '和多田萌衣'
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Home,
    meta: { title: '『私が女優になる日＿』season3 - 視聴者キャスティング', desc: 'スター育成プロジェクト『私が女優になる日＿』season3｜TBSテレビ' }
  },
  {
    path: '/user_profile',
    component: UserProfile,
    meta: { title: '『私が女優になる日＿』season3 - 投票者登録', desc: 'スター育成プロジェクト『私が女優になる日＿』season3｜TBSテレビ' }
  },
  {
    path: '/list',
    name: 'List',
    component: List,
    meta: { title: '『私が女優になる日＿』season3 - 一覧', desc: 'スター育成プロジェクト『私が女優になる日＿』season3｜TBSテレビ' }
  },
  {
    path: '/detail/:id',
    name: 'Detail',
    component: Detail,
    meta: { title: '『私が女優になる日＿』season3 - ', desc: 'スター育成プロジェクト『私が女優になる日＿』season3｜TBSテレビ' }
  },
  // {
  //   path: '/final',
  //   name: 'Final',
  //   component: Final,
  //   meta: { title: '『私が女優になる日＿』season3 - ', desc: 'スター育成プロジェクト『私が女優になる日＿』season3｜TBSテレビ' }
  // },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFoundError
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior (to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name === 'Detail') {
    const name = Object.entries(actressList).find(e => e[0].toString() === ('000' + to.params.id).slice(-3))
    if (name) {
      to.meta.title += name[1]
    } else {
      next('/')
      return
    }
  }
  next()
})

export default router
