import { createStore, Store } from 'vuex'
import { InjectionKey } from 'vue'
import * as type from '../types/'

export interface State {
  headerShow: boolean,
  isModalOpen: boolean,
  backPage: string,
  userAttribute: boolean | undefined,
  vote: type.Vote,
  voted: boolean,
  liff: {
    Language: string,
    Version: string,
    InClient: boolean,
    LoggedIn: boolean,
    OS: string | undefined,
    LineVersion: string | null,
    userId: string,
    idToken: string
  }
}

// インジェクションキーを定義します
// eslint-disable-next-line symbol-description
export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore<State>({
  state: {
    isModalOpen: false,
    headerShow: true,
    backPage: '',
    userAttribute: undefined,
    vote: { vote1: '', vote2: '', vote3: '', vote4: '' },
    voted: true,
    liff: {
      Language: 'none',
      Version: '',
      InClient: false,
      LoggedIn: false,
      OS: '',
      LineVersion: '',
      userId: '',
      idToken: ''
    }
  },
  getters: {
    getUserAttribute: state => {
      return state.userAttribute
    },
    getVote: state => {
      return state.vote
    },
    getVoted: state => {
      return state.voted
    },
    headerShow: state => {
      return state.headerShow
    },
    backPage: state => {
      return state.backPage
    },
    getLanguage: state => {
      return state.liff.Language
    },
    getVersion: state => {
      return state.liff.Version
    },
    isInClient: state => {
      return state.liff.InClient
    },
    isLoggedIn: state => {
      return state.liff.LoggedIn
    },
    getOS: state => {
      return state.liff.OS
    },
    getLineVersion: state => {
      return state.liff.LineVersion
    },
    getUserId: state => {
      return state.liff.userId
    },
    getIdToken: state => {
      return state.liff.idToken
    }
  },
  mutations: {
    setHeaderShow (state, val) {
      state.headerShow = val
    },
    setBackPage (state, val) {
      state.backPage = val
    },
    setVote (state, val) {
      state.vote = val
    },
    setVoted (state, val) {
      state.voted = val
    },
    setUserAttribute (state, val) {
      state.userAttribute = val
    },
    setLanguage (state, val) {
      state.liff.Language = val
    },
    setVersion (state, val) {
      state.liff.Version = val
    },
    setInClient (state, val) {
      state.liff.InClient = val
    },
    setLoggedIn (state, val) {
      state.liff.LoggedIn = val
    },
    setOS (state, val) {
      state.liff.OS = val
    },
    getLineVersion (state, val) {
      state.liff.LineVersion = val
    }
  },
  actions: {},
  modules: {}
})
