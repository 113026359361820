
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import ApiService from '@/module/ApiService'

@Options({
  data () {
    return {
      actress: {
        entry_no: '',
        status: 0,
        name: '',
        kana: '',
        place: '',
        birthday: '',
        age: '',
        height: '',
        thumbnail: [],
        photo: [],
        video: [],
        profile1: '',
        profile2: '',
        profile3: '',
        profile4: ''
      },
      select: 1,
      LIFF_ID: process.env.VUE_APP_LIFF_ID,
      shareURL: window.location.href
    }
  },
  created () {
    const apiService = new ApiService()
    apiService.getActressList().then((ret) => {
      this.actress = ret.data.actress[this.$route.params.id]
    })
  },
  mounted () {
    this.$store.commit('setBackPage', '/list')
    this.$store.commit('setHeaderShow', true)
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'getUserId', 'getIdToken', 'backPage']),
    videoList () {
      return this.actress.video.slice()
    }
  },
  methods: {
    scrollVideo () {
      const element = document.getElementById('video')
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 20,
          left: 100,
          behavior: 'smooth'
        })
      }
    }
  }
})
export default class Detail extends Vue {
}
