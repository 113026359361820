
import { Options, Vue } from 'vue-class-component'
import liff from '@line/liff'
import { mapGetters } from 'vuex'

@Options({
  computed: {
    ...mapGetters({
      getUserAttribute: 'getUserAttribute',
      isInClient: 'isInClient',
      isLoggedIn: 'isLoggedIn',
      getUserId: 'getUserId',
      LineVersion: 'getLineVersion'
    })
  },
  mounted () {
    this.$store.commit('setBackPage', '')
    this.$store.commit('setHeaderShow', false)
  },
  methods: {
    login: function () {
      liff.login()
    },
    logout: function () {
      liff.logout()
      window.location.reload()
    },
    nextPage: function () {
      if (this.isLoggedIn && !this.getUserAttribute && localStorage.getItem('userAttribute') !== 'true') {
        return 'user_profile'
      }
      return 'list'
    }
  }
})
export default class Home extends Vue {
}
