
import ApiService from '@/module/ApiService'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'
import { Actress } from '@/types'
import * as type from '@/types'

@Options({
  created () {
    const apiService = new ApiService()
    apiService.getActressList().then((ret) => {
      Object.entries(ret.data.actress).forEach((value) => {
        this.actresses.push(value[1])
      })
      console.log(this.getVote)
    })
  },
  data () {
    return {
      actresses: [] as Actress[],
      voted: false,
      modal: false,
      confirm_modal: false,
      reset_modal: false,
      VideoUrl: '',
      videoList: [
        { type: 'default', src: 'https://www.youtube.com/embed/ofRhAB2wRdI', thumbnail: 'actress/video/f01.jpg', caption: '', caption2: '大和奈央' },
        { type: 'default', src: 'https://www.youtube.com/embed/tMT5SReVMGs', thumbnail: 'actress/video/f02.jpg', caption: '', caption2: '和多田萌衣' },
        { type: 'default', src: 'https://www.youtube.com/embed/jV27CS4YZWA', thumbnail: 'actress/video/f03.jpg', caption: '', caption2: '岸本小雪' },
        { type: 'default', src: 'https://www.youtube.com/embed/V1LhvHivl3M', thumbnail: 'actress/video/f04.jpg', caption: '', caption2: '坂本彩' }
      ],
      error1: false,
      error2: false,
      error3: false,
      error4: false,
      errorSelect: [
        false,
        false,
        false,
        false
      ],
      voteReset: false
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'getUserId', 'backPage', 'getVote', 'getVoted', 'getIdToken']),
    currentVote (): type.Vote {
      return {
        vote1: this.getVote.vote1,
        vote2: this.getVote.vote2,
        vote3: this.getVote.vote3,
        vote4: this.getVote.vote4
      }
    },
    actressesList: function () {
      // 並び替え
      console.log(this.actresses)
      return this.actresses.filter((item: Actress) => {
        return ['002', '004', '008', '009'].includes(item.entry_no)
      }).sort((a: Actress, b: Actress) => {
        // // ランダム
        // return Math.random() - 0.5

        // // 昇順
        // return (a.entry_no > b.entry_no) ? 1 : -1

        // 降順
        return (a.entry_no < b.entry_no) ? 1 : -1
      })
    }
  },
  mounted () {
    this.$store.commit('setBackPage', '/')
    this.$store.commit('setHeaderShow', true)
  },
  methods: {
    isMobile () {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    vote ($event: any) {
      console.log($event)
      if ($event.target.classList.contains('disable')) {
        return false
      }
      const votes: type.Vote = this.currentVote

      // バリデーション
      if (this.validate()) return false

      const apiService = new ApiService()
      apiService.vote(this.getUserId, votes, this.getIdToken).then((ret) => {
        console.log(ret)
        if (ret.status === 200) {
          const vote: type.Vote = { vote1: votes.vote1, vote2: votes.vote2, vote3: votes.vote3, vote4: votes.vote4 }
          this.$store.commit('setVote', vote)
        }
      })
      this.$store.commit('setVoted', true)
      this.voteReset = false
    },
    validate () {
      this.error1 = this.getVote.vote1 === '0' || this.getVote.vote2 === '0' || this.getVote.vote3 === '0' || this.getVote.vote4 === '0'

      if (!this.error1) {
        const arrayVotes = [this.getVote.vote1, this.getVote.vote2, this.getVote.vote3, this.getVote.vote4]
        //  初期化
        this.error2 = false
        this.errorSelect.forEach((val: any, i: string | number) => {
          this.errorSelect[i] = false
        })
        arrayVotes.forEach((val, i, array) => {
          //  現在の値での捜索結果と現在のキーが違う場合は重複
          if (array.indexOf(val) !== i) {
            this.errorSelect[array.indexOf(val)] = true
            this.errorSelect[i] = true
            this.error2 = true
          }
        })
      } else {
        this.errorSelect[0] = this.getVote.vote1 === '0'
        this.errorSelect[1] = this.getVote.vote2 === '0'
        this.errorSelect[2] = this.getVote.vote3 === '0'
        this.errorSelect[3] = this.getVote.vote4 === '0'
      }

      return (this.error1 || this.error2)
    },
    openModal (url: string) {
      console.log(url)
      this.modal = true
      this.VideoUrl = url
    },
    closeModal () {
      this.modal = false
      this.VideoUrl = ''
    },
    reset () {
      this.$store.commit('setVoted', false)
      this.$store.commit('setVote', { vote1: '0', vote2: '0', vote3: '0', vote4: '0' })
      this.voteReset = true
    }
  }
})
export default class List extends Vue {
}
